import { Delete, Edit, ContentCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton,
  Snackbar,
  Alert,
  Typography
} from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../authContext';
import { firestore } from '../../../firebase';
import ErrorPage from '../../../components/ErrorPage';
import ContactFormDialog from '../../../components/ContactFormDialog';
import { DataGrid } from '@mui/x-data-grid';

const ContactsPage = () => {
  const [contacts, setContacts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [initialContact, setInitialContact] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const LoadingAnimation = '/loading_animation.svg';

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userRoles = userDoc.data().roles || [];
          let hasAdminPermission = false;
          for (const roleId of userRoles) {
            const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
            if (roleDoc.exists()) {
              const rolePermissions = roleDoc.data().permissions || [];
              if (rolePermissions.includes("manage_calls") || rolePermissions.includes("admin")) {
                hasAdminPermission = true;
                break;
              }
            }
          }
          setIsAdmin(hasAdminPermission);
        } else {
          setIsAdmin(false);
        }
      }
      setLoading(false);
    };

    const fetchContacts = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'contacts'));
      const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContacts(contactsData);
    };

    checkAdminRole();
    fetchContacts();
  }, [currentUser]);

  const handleOpenDialog = () => {
    // Creating a new contact - no initial contact
    setInitialContact(null);
    setEditMode(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteContactId(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'contacts', deleteContactId));
      setContacts(contacts.filter(contact => contact.id !== deleteContactId));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting contact:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error deleting contact. Please try again.');
    }
  };

  const handleEdit = (contact) => {
    setInitialContact(contact);
    setEditMode(true);
    setOpenDialog(true);
  };

  // const handleDuplicate = (contact) => {
  //   // On duplication, we pass the existing contact as initialContact.
  //   // This allows user to simply change role or other fields and then save as new contact.
  //   // We consider this also an "editMode" scenario since we prefill fields, but we are actually creating a new doc.
  //   // To differentiate from true editing, we might not call it editMode, but reuse the dialog with initialContact.
  //   // Let's keep editMode true just to reuse the same UI. The dialog title says "Edit/Duplicate".
  //   setInitialContact(contact);
  //   setEditMode(true);
  //   setOpenDialog(true);
  // };

  // const handleSaveContact = async (data) => {
  //   try {
  //     // If we have an initialContact and we are truly editing that contact (not duplicating),
  //     // we would have a way to differentiate editing vs. duplicating.
  //     // One approach: if initialContact is set and editMode is true, check if the IDs are the same.
  //     // If we want a "duplicate" action to always create a new one, we can detect that by comparing data or pass a separate flag.
  //     //
  //     // Let's define: if initialContact is present but user triggered duplication, we won't use updateDoc. We'll always add a new doc.
  //     // We can handle this logic by comparing if the openDialog was triggered by edit or duplicate.
  //     //
  //     // Let's say:
  //     // - If it's truly edit mode, we have an initialContact and we do updateDoc.
  //     // - If it's duplication, we also have an initialContact, but we explicitly know the user pressed Duplicate.
  //     //   Let's keep a simple approach: if onSaveContact is called and we have an initialContact and editMode=true,
  //     //   we first check if user triggered the dialog from edit or duplicate:
  //     //   We'll store a separate boolean or check the ID. If initialContact.id exists, editing that ID.
  //     //   If we want duplication to always create a new doc, let's introduce a "isDuplicate" condition.

  //     const isDuplicate = initialContact && editMode && !contacts.find(c => c.id === initialContact.id); 
  //     // This check won't work if we haven't updated contacts. Let's just add a "duplicate" button that sets a variable.
  //     // Simpler approach: Add a state variable `isDuplicateMode`.

  //     // Actually, let's define a state `isDuplicateMode` for clarity:
  //     // We'll set it to true when user clicks duplicate button and false otherwise.
  //     // Then if `isDuplicateMode` is true, we add a new doc.
  //     // If `isDuplicateMode` is false and `initialContact` is not null, we updateDoc.
  //     // If `initialContact` is null, we create new doc.

  //     // For now, let's introduce isDuplicateMode as a state:
  //   } catch (err) {
  //     console.error('Error creating/updating contact:', err);
  //     setOpenSnackbar(true);
  //     setErrorMessage('Error creating/updating contact. Please try again.');
  //   }
  // };

  // Let's add isDuplicateMode state:
  const [isDuplicateMode, setIsDuplicateMode] = useState(false);

  const handleDuplicateButtonClick = (contact) => {
    setInitialContact(contact);
    setEditMode(true);
    setIsDuplicateMode(true);
    setOpenDialog(true);
  };

  const handleSaveContactFinal = async (data) => {
    try {
      if (isDuplicateMode) {
        // Create a new contact based on data:
        const newDoc = await addDoc(collection(firestore, 'contacts'), data);
        setContacts([...contacts, { id: newDoc.id, ...data }]);
      } else if (initialContact && editMode) {
        // Update existing contact
        const contactRef = doc(firestore, 'contacts', initialContact.id);
        await updateDoc(contactRef, data);
        setContacts(contacts.map(contact => contact.id === initialContact.id ? { ...contact, ...data } : contact));
      } else {
        // New contact creation
        const newDoc = await addDoc(collection(firestore, 'contacts'), data);
        setContacts([...contacts, { id: newDoc.id, ...data }]);
      }
      setIsDuplicateMode(false);
      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating contact:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error creating/updating contact. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return <img src={LoadingAnimation} alt="Loading" style={{ width: '150px' }} />;
  }
  if (!isAdmin) {
    return <ErrorPage message="You do not have permission to manage calls." />;
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
    { field: 'role', headerName: 'Role', flex: 1, minWidth: 150 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
    { field: 'phone', headerName: 'Phone', flex: 1, minWidth: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        const contact = params.row;
        return (
          <Box display="flex" gap={1}>
            <IconButton onClick={() => handleEdit(contact)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDuplicateButtonClick(contact)}>
              <ContentCopy />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteDialog(contact.id)}>
              <Delete />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Manage Contacts</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add Contact
        </Button>
      </Box>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={contacts}
          columns={columns}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>

      <ContactFormDialog
        open={openDialog}
        editMode={editMode || isDuplicateMode}
        initialContact={initialContact}
        onClose={() => { setIsDuplicateMode(false); handleCloseDialog(); }}
        onSave={handleSaveContactFinal}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this contact?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactsPage;
