// RoleSelector.js
import React, { useState } from 'react';
import {
  Popper,
  Paper,
  ClickAwayListener,
  Button,
  Typography,
  Grow,
  TextField,
  Box
} from '@mui/material';

const availableRoles = ['Head-Organiser', 'Mentor', 'Editor', 'Head of Jury', 'President', 'Vice-President'];

function RoleSelector({ anchorEl, open, onClose, onSelectRole }) {
  const [customRole, setCustomRole] = useState('');

  const handleCustomRoleSelect = () => {
    if (customRole.trim()) {
      onSelectRole(customRole.trim());
      setCustomRole('');
    }
  };

  return (
    <Popper open={open} anchorEl={anchorEl} transition sx={{ zIndex: 2000 }}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top', zIndex: 9999 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <ClickAwayListener onClickAway={onClose}>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Select Role
                </Typography>
                {availableRoles.map((role) => (
                  <Button
                    key={role}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                    onClick={() => onSelectRole(role)}
                  >
                    {role}
                  </Button>
                ))}
                <Box mt={2} display="flex" alignItems="center">
                  <TextField
                    label="Custom Role"
                    variant="outlined"
                    size="small"
                    value={customRole}
                    onChange={(e) => setCustomRole(e.target.value)}
                    sx={{ mr: 1 }}
                  />
                  <Button variant="contained" onClick={handleCustomRoleSelect}>
                    Apply
                  </Button>
                </Box>
                <Button variant="text" color="error" onClick={onClose} sx={{ mt: 2 }}>
                  Cancel
                </Button>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default RoleSelector;
