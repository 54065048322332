// DynamicTable.js
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button
} from '@mui/material';

const DynamicTable = forwardRef(({ initialTables, onSave }, ref) => {
  // Use the parent's tables (if provided) as initial state,
  // otherwise start with a default table.
  const [tables, setTables] = useState(
    (initialTables && initialTables.length > 0)
      ? initialTables
      : [{ id: 1, scheduleName: 'Schedule 1', columns: ['Time'], rows: [{}] }]
  );

  // When the parent's initialTables prop changes (e.g. when going back),
  // update the internal state.
  useEffect(() => {
    if (initialTables && initialTables.length > 0) {
      setTables(initialTables);
    }
  }, [initialTables]);

  // Expose a method to the parent that returns the current table state.
  useImperativeHandle(ref, () => ({
    getTables: () => tables
  }));

  // --- Table manipulation functions ---

  const handleAddTable = () => {
    const newTable = {
      id: tables.length + 1,
      scheduleName: `Schedule ${tables.length + 1}`,
      columns: ['Time'],
      rows: [{}]
    };
    setTables([...tables, newTable]);
  };

  const handleAddColumn = (tableId) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newColumnName = `Column ${table.columns.length + 1}`;
        const newColumns = [...table.columns, newColumnName];
        const newRows = table.rows.map(row => ({ ...row, [newColumnName]: '' }));
        return { ...table, columns: newColumns, rows: newRows };
      }
      return table;
    }));
  };

  const handleAddRow = (tableId) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newRow = {};
        table.columns.forEach(col => {
          newRow[col] = '';
        });
        return { ...table, rows: [...table.rows, newRow] };
      }
      return table;
    }));
  };

  const handleDeleteRow = (tableId, rowIndex) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newRows = table.rows.filter((_, index) => index !== rowIndex);
        return { ...table, rows: newRows };
      }
      return table;
    }));
  };

  const handleDeleteColumn = (tableId, colIndex) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newColumns = table.columns.filter((_, index) => index !== colIndex);
        const newRows = table.rows.map(row => {
          const newRow = { ...row };
          delete newRow[table.columns[colIndex]];
          return newRow;
        });
        return { ...table, columns: newColumns, rows: newRows };
      }
      return table;
    }));
  };

  const handleDeleteTable = (tableId) => {
    setTables(tables.filter(table => table.id !== tableId));
  };

  const handleCellChange = (tableId, rowIndex, colIndex, value) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newRows = [...table.rows];
        newRows[rowIndex][table.columns[colIndex]] = value;
        return { ...table, rows: newRows };
      }
      return table;
    }));
  };

  const handleColumnRename = (tableId, colIndex, value) => {
    setTables(tables.map(table => {
      if (table.id === tableId) {
        const newColumns = [...table.columns];
        const oldColumnName = newColumns[colIndex];
        newColumns[colIndex] = value;
        const newRows = table.rows.map(row => {
          const newRow = { ...row };
          newRow[value] = row[oldColumnName];
          delete newRow[oldColumnName];
          return newRow;
        });
        return { ...table, columns: newColumns, rows: newRows };
      }
      return table;
    }));
  };

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>
        Event Schedules
      </Typography>
      {tables.map((table) => (
        <Box key={table.id} mb={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
              label="Schedule Name"
              value={table.scheduleName}
              onChange={(e) =>
                setTables(tables.map(t =>
                  t.id === table.id ? { ...table, scheduleName: e.target.value } : t
                ))
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <IconButton onClick={() => handleDeleteTable(table.id)} color="error">
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {table.columns.map((col, colIndex) => (
                    <TableCell key={colIndex}>
                      <Box display="flex" alignItems="center">
                        <TextField
                          value={col}
                          onChange={(e) => handleColumnRename(table.id, colIndex, e.target.value)}
                          fullWidth
                        />
                        <IconButton onClick={() => handleDeleteColumn(table.id, colIndex)} color="error">
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton onClick={() => handleAddColumn(table.id)} aria-label="add column">
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table.rows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {table.columns.map((col, colIndex) => (
                      <TableCell key={colIndex}>
                        <TextField
                          value={row[col] || ''}
                          onChange={(e) => handleCellChange(table.id, rowIndex, colIndex, e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.target.blur();
                            }
                          }}
                          fullWidth
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(table.id, rowIndex)} color="error">
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={table.columns.length + 1}>
                    <IconButton onClick={() => handleAddRow(table.id)} aria-label="add row">
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
      <Button variant="contained" color="primary" onClick={handleAddTable} sx={{ mb: 2 }}>
        Add New Schedule
      </Button>
    </Box>
  );
});

export default DynamicTable;
