import { 
  Button, 
  Card, 
  CardContent, 
  Container, 
  Typography, 
  Grid, 
  Box, 
  Divider, 
  Avatar,
  Tooltip,
  IconButton
} from '@mui/material';
import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { firestore } from '../firebase';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ReadMore from '../components/ReadMore'; // Reuse ReadMore component
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EventsPage = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [openCalls, setOpenCalls] = useState([]);
  const [eventMap, setEventMap] = useState({}); // For looking up events by ID
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventsAndCalls = async () => {
      try {
        const eventsCollectionRef = collection(firestore, 'events');
        const callsCollectionRef = collection(firestore, 'calls');
        const now = Timestamp.fromDate(new Date());

        const upcomingQuery = query(eventsCollectionRef, where('endDate', '>=', now));
        const pastQuery = query(eventsCollectionRef, where('endDate', '<', now));
        const callsQuery = query(callsCollectionRef, where('endDate', '>=', now));

        // Fetch events and calls in parallel
        const [upcomingSnapshot, pastSnapshot, callsSnapshot] = await Promise.all([
          getDocs(upcomingQuery),
          getDocs(pastQuery),
          getDocs(callsQuery),
        ]);

        // Convert snapshots to arrays
        const upcomingList = upcomingSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const pastList = pastSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const callsList = callsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setUpcomingEvents(upcomingList);
        setPastEvents(pastList);
        setOpenCalls(callsList);

        // Build an event map: ID -> event (combining upcoming and past)
        const allEvents = [...upcomingList, ...pastList];
        const map = {};
        allEvents.forEach((evt) => {
          map[evt.id] = evt;
        });
        setEventMap(map);

      } catch (err) {
        console.error('Error fetching events and calls:', err);
        setError(err.message);
      }
    };

    fetchEventsAndCalls();
  }, []);

  // ----------------------------
  // RENDER EVENT CARD
  // ----------------------------
  const renderEventCard = (event) => (
    <Card 
      key={event.id} 
      sx={{ mb: 4, display: 'flex', alignItems: 'center', ':hover': { boxShadow: 6 }}} 
      elevation={3}
    >
      <CardContent sx={{ flex: 1 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <EventAvailableIcon color="primary" sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            component={RouterLink}
            to={`/events/${event.id}`}
            sx={{ textDecoration: 'none', color: 'primary.main' }}
          >
            {event.title}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {new Date(event.startDate.toDate()).toLocaleDateString()} -{' '}
          {new Date(event.endDate.toDate()).toLocaleDateString()}
        </Typography>
        <Button
          component={RouterLink}
          to={`/events/${event.id}`}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Learn More
        </Button>
      </CardContent>
      {event.logoUrl && (
        <Avatar
          src={event.logoUrl}
          alt={`${event.title} logo`}
          sx={{ width: 100, height: 100, mr: 2 }}
          variant="rounded"
        />
      )}
    </Card>
  );

  // ----------------------------
  // RENDER CALL CARD
  // ----------------------------
  const renderCallCard = (call, eventMap) => {
    // If the call references an event, retrieve it from eventMap.
    const linkedEvent = call.eventId ? eventMap[call.eventId] : null;
  
    return (
      <Card
        key={call.id}
        sx={{
          mb: 2,
          ':hover': { boxShadow: 4 },
          overflow: 'hidden'
        }}
        elevation={2}
      >
        <CardContent sx={{ p: 2 }}>
          {/* Header with icon and call title */}
          <Box display="flex" alignItems="center">
            <AnnouncementIcon color="primary" sx={{ mr: 1, fontSize: 28 }} />
            <Typography variant="subtitle1" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
              {call.title}
            </Typography>
          </Box>
  
          {/* For delegate calls, display the delegate badge and align the Apply Now button to the right */}
          {call.isDelegateCall ? (
            <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="caption"
                sx={{
                  color: 'primary.main',
                  px: 1,
                  py: 0.25,
                  borderRadius: 1,
                  fontWeight: 'bold',
                  border: 1,       // This adds a 1px solid border
                  borderColor: 'primary.main',
                }}                
              >
                DELEGATE CALL
              </Typography>
              <Button
                component="a"
                href={call.applyLink}
                target="_blank"
                rel="noopener noreferrer"
                variant="text"
                size="small"
              >
                Apply Now
              </Button>
            </Box>
          ) : (
            <Box mt={1}>
              <Button
                component="a"
                href={call.applyLink}
                target="_blank"
                rel="noopener noreferrer"
                variant="text"
                size="small"
              >
                Apply Now
              </Button>
            </Box>
          )}
  
          {/* Call description */}
          <Box mt={1}>
            <ReadMore text={call.description} limit={100} />
          </Box>
  
          {/* Row for apply deadline and linked event */}
          <Box
            mt={1}
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="text.secondary">
              <strong>Apply before:</strong> {new Date(call.endDate.toDate()).toLocaleDateString()}
            </Typography>
            {linkedEvent && (
              <Typography variant="body2" color="text.secondary">
                Linked to:&nbsp;
                <RouterLink
                  to={`/events/${linkedEvent.id}`}
                  style={{ textDecoration: 'none', color: '#1976d2' }}
                >
                  {linkedEvent.title || linkedEvent.id}
                </RouterLink>
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };  

  // ----------------------------
  // SORT CALLS: Delegate calls always on top
  // ----------------------------
  const sortedCalls = [...openCalls].sort((a, b) => {
    if (a.isDelegateCall && !b.isDelegateCall) return -1;
    if (!a.isDelegateCall && b.isDelegateCall) return 1;
    return 0;
  });

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography color="error">Error: {error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {/* MAIN SECTION FOR EVENTS */}
        <Grid item xs={12} md={8} sx={{ pr: 2 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <EventAvailableIcon color="primary" sx={{ mr: 1, fontSize: 36 }} />
            <Typography variant="h4" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>
              Upcoming Events
            </Typography>
          </Box>
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map(renderEventCard)
          ) : (
            <Typography variant="body1">There are no upcoming events.</Typography>
          )}

          <Divider sx={{ my: 4 }} />

          <Box display="flex" alignItems="center" mb={2}>
            <EventAvailableIcon color="secondary" sx={{ mr: 1, fontSize: 36 }} />
            <Typography variant="h4" sx={{ color: 'secondary.dark', fontWeight: 'bold' }}>
              Past Events
            </Typography>
          </Box>
          {pastEvents.length > 0 ? (
            pastEvents.map(renderEventCard)
          ) : (
            <Typography variant="body1">There are no past events.</Typography>
          )}
        </Grid>

        {/* SIDEBAR FOR OPEN CALLS */}
        <Divider orientation="vertical" flexItem sx={{ mr: -1 }} />
        <Grid item xs={12} md={4}>
          <Box sx={{ position: 'sticky', top: 20, ml: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <AnnouncementIcon color="primary" sx={{ mr: 1, fontSize: 32 }} />
              <Typography variant="h5" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>
                Open Calls
              </Typography>
            </Box>
            {sortedCalls.length > 0 ? (
              sortedCalls.map(renderCallCard)
            ) : (
              <Typography variant="body1">There are no open calls at the moment.</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventsPage;
