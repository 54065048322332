import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Chip,
  Tooltip,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import truncate from 'html-truncate'; // Import the library

const NewsPage = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;
  const [activeCategory, setActiveCategory] = useState('');
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    const fetchNewsArticles = async () => {
      const db = getFirestore();
      const newsSnapshot = await getDocs(collection(db, 'news'));
      const newsData = newsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setNewsArticles(newsData);
      setFilteredArticles(newsData);
    };

    fetchNewsArticles();
  }, []);

  const handleCategoryClick = (category) => {
    const newFilteredArticles = category 
      ? newsArticles.filter((article) => article.category === category || !article.category)
      : newsArticles;
    setFilteredArticles(newFilteredArticles);
    setCurrentPage(1);
    setActiveCategory(category);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const categories = [...new Set(newsArticles.map((article) => article.category).filter(Boolean))];

  // Open modal with full news article
  const openNewsModal = (article, e) => {
    if (e) e.stopPropagation();
    setSelectedNews(article);
  };

  const closeNewsModal = () => {
    setSelectedNews(null);
  };

  // Render a news card with the same grid layout.
  const renderNewsCard = (article) => (
    <Grid item xs={12} sm={6} md={4} key={article.id}>
      <Card
        sx={{
          boxShadow: 2,
          borderRadius: 2,
          overflow: 'hidden',
          transition: 'transform 0.3s ease-in-out',
          backgroundColor: '#ffffff',
          '&:hover': { transform: 'scale(1.03)', cursor: 'pointer' },
          mb: 4,
        }}
        onClick={() => openNewsModal(article)}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ color: '#003366', fontWeight: 'bold' }}>
            {article.title}
          </Typography>
          {/* Here we truncate the HTML content safely */}
          <Typography
            variant="body2"
            color="textSecondary"
            paragraph
            dangerouslySetInnerHTML={{
              __html:
                article.content.length > 80
                  ? truncate(article.content, 80, { ellipsis: '...' })
                  : article.content,
            }}
          />
          {article.category && (
            <Breadcrumbs separator="|" sx={{ mt: 1 }}>
              <Chip label={article.category} sx={{ cursor: 'default' }} />
            </Breadcrumbs>
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {/* Header Section */}
        <Box textAlign="center" py={5}>
          <Typography variant="h3" gutterBottom sx={{ color: '#003366', fontWeight: 'bold' }}>
            Latest News
          </Typography>
          <Divider variant="middle" sx={{ my: 3, borderColor: '#003366', width: '50%', mx: 'auto' }} />
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Stay updated with the latest news and articles.
          </Typography>
        </Box>

        {/* Categories Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ color: '#003366', fontWeight: 'bold', display: 'inline-block', mr: 1 }}>
            Categories
          </Typography>
          <Tooltip title="Click on a category to filter news articles by topic." arrow>
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Breadcrumbs separator="|" sx={{ mt: 1 }}>
            <Chip
              label="# All"
              onClick={() => handleCategoryClick('')}
              sx={{
                cursor: 'pointer',
                fontWeight: 'bold',
                backgroundColor: activeCategory === '' ? '#FFA500' : 'transparent',
              }}
            />
            {categories.map((cat, index) => (
              <Chip
                key={index}
                label={`# ${cat}`}
                onClick={() => handleCategoryClick(cat)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: activeCategory === cat ? '#FFA500' : '#eee' },
                  backgroundColor: activeCategory === cat ? '#FFA500' : 'transparent',
                }}
              />
            ))}
          </Breadcrumbs>
        </Box>

        {/* News Articles Grid */}
        <Grid container spacing={4} sx={{ mt: 3 }}>
          {currentArticles.map((article) => renderNewsCard(article))}
        </Grid>

        {/* Pagination */}
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(filteredArticles.length / articlesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Container>

      {/* Modal Popup for Full News Details */}
      {selectedNews && (
        <Dialog
          open={Boolean(selectedNews)}
          onClose={closeNewsModal}
          fullWidth
          maxWidth="md"
          sx={{ '& .MuiDialog-paper': { borderRadius: 2 } }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {selectedNews.title}
            <IconButton
              aria-label="close"
              onClick={closeNewsModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {selectedNews.bannerImageUrl && (
              <Box
                component="img"
                src={selectedNews.bannerImageUrl}
                alt={selectedNews.title}
                sx={{
                  width: '100%',
                  height: 300,
                  objectFit: 'cover',
                  mb: 2,
                  borderRadius: 1,
                }}
              />
            )}
            {selectedNews.createdAt && (
              <Typography variant="caption" color="textSecondary" sx={{ mb: 1, display: 'block' }}>
                {new Date(selectedNews.createdAt.toDate()).toLocaleDateString()}
              </Typography>
            )}
            <Typography variant="body1" sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: selectedNews.content }} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default NewsPage;
