import React, { useState, useEffect } from 'react';
import { Container, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Fab, Tooltip } from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { getFirestore, collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import EditQuote from '../../../components/quotes/EditQuote';
import QuoteCard from '../../../components/quotes/QuoteCard';
import CreateQuote from '../../../components/quotes/CreateQuote';

const AdminQuotesPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null); // For editing a quote
  const [deleteQuoteId, setDeleteQuoteId] = useState(null); // For delete confirmation
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // Confirm delete dialog
  const [createDialogOpen, setCreateDialogOpen] = useState(false); // For creating new quotes
  const db = getFirestore();

  useEffect(() => {
    const fetchQuotes = async () => {
      const quotesSnapshot = await getDocs(collection(db, 'quotes'));
      const quotesData = quotesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setQuotes(quotesData);
    };
    fetchQuotes();
  }, [db]);

  const handleDelete = async (quoteId) => {
    try {
      await deleteDoc(doc(db, 'quotes', quoteId));
      setQuotes(quotes.filter((quote) => quote.id !== quoteId));
      setDeleteDialogOpen(false); // Close dialog after deletion
    } catch (err) {
      console.error('Error deleting quote:', err);
    }
  };

  const handleEdit = (quote) => {
    setSelectedQuote(quote); // Set selected quote for editing
  };

  const handleCloseEditDialog = (updatedQuote) => {
    if (updatedQuote) {
      setQuotes((prevQuotes) =>
        prevQuotes.map((quote) => (quote.id === updatedQuote.id ? updatedQuote : quote))
      );
    }
    setSelectedQuote(null);
  };
  

  const handleAddNewQuote = (newQuote) => {
    setQuotes((prevQuotes) => [...prevQuotes, newQuote]);
    setCreateDialogOpen(false); // Close dialog after creating a new quote
  };

  // const handleCloseCreateDialog = () => {
  //   setCreateDialogOpen(false);
  // };

  const handleDeleteDialogOpen = (quoteId) => {
    setDeleteQuoteId(quoteId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        Manage Quotes
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" mb={4}>
        Add, edit, or delete quotes below. Your changes will be immediately reflected.
      </Typography>

      <Grid container spacing={4}>
        {quotes.map((quote) => (
          <Grid item xs={12} sm={6} md={4} key={quote.id}>
            <QuoteCard
              quote={quote}
              actions={
                <>
                  <IconButton onClick={() => handleEdit(quote)}>
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteDialogOpen(quote.id)}>
                    <Delete color="secondary" />
                  </IconButton>
                </>
              }
            />
          </Grid>
        ))}
      </Grid>

      {/* Floating Add Button */}
      <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Fab color="primary" aria-label="add" onClick={() => setCreateDialogOpen(true)}>
          <Add />
          <Tooltip title="Add New Quote" placement="left">
            <Add />
          </Tooltip>
        </Fab>
      </Box>

      {/* Edit Quote Dialog */}
      {selectedQuote && (
        <EditQuote
          currentQuote={selectedQuote}
          open={Boolean(selectedQuote)}
          onClose={handleCloseEditDialog}
        />
      )}

      {/* Create New Quote Dialog */}
      <CreateQuote open={createDialogOpen} onClose={handleAddNewQuote} />

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this quote?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={() => handleDelete(deleteQuoteId)} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminQuotesPage;
